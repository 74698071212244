@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
.container__clz {
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 23px;
    -webkit-column-gap: 23px;
            column-gap: 23px;
    display: flex;
    grid-column-gap: 34px;
    -webkit-column-gap: 34px;
            column-gap: 34px;
}
.third-container__clz {
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 23px;
    -webkit-column-gap: 23px;
            column-gap: 23px;
    display: flex;
    grid-column-gap: 34px;
    -webkit-column-gap: 34px;
            column-gap: 34px;
}

.second-container__clz {
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-column-gap: 23px;
    -webkit-column-gap: 23px;
            column-gap: 23px;
    display: flex;
    grid-column-gap: 34px;
    -webkit-column-gap: 34px;
            column-gap: 34px;
}

@media screen and (min-width: 2288px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 377px;
        padding-right: 309px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

@media screen and (min-width: 2090px) and (max-width: 2287px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 271px;
        padding-right: 217px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1900px) and (max-width: 2089px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 208px;
        padding-right: 61px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1781px) and (max-width: 1900px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 98px;
        padding-right: 81px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1669px) and (max-width: 1780px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 67px;
        padding-right: 0px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1669px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 110px;
        padding-right: 60px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1317px) and (max-width: 1366px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 60px;
        padding-right: 60px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1237px) and (max-width: 1316px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 20px;
        padding-right: 20px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1236px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 200px;
        padding-right: 20px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}
@media screen and (min-width: 942px) and (max-width: 1099px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 130px;
        padding-right: 20px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

@media screen and (min-width: 832px) and (max-width: 941px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 20px;
        padding-right: 20px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

@media screen and (min-width: 688px) and (max-width: 831px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 200px;
        padding-right: 20px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}
@media screen and (min-width: 600px) and (max-width: 687px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 130px;
        padding-right: 20px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

@media screen and (min-width: 0px) and (max-width: 599px) {
    .cards {
        padding-bottom: 100px;
        padding-left: 34px;
        padding-right: 36px;
        display: grid;
        grid-row-gap: 20px;
        row-gap: 20px;
    }
}

.card-header__clz img {
    display: block;
    border: 0;
    width: 100%;
    height: 259px;
}
.card-body__clz {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* padding-top: 15px;
    padding-left: 10px; */
    min-height: 130px;
    padding: 20px;
}
.card__clz {
    margin: 10px;
    background-color: rgb(8, 8, 8);
    border-radius: 18px;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    width: 351px;
    height: 448px;
    display: flex;
    flex-direction: column;
    position: relative;
    -webkit-filter: grayscale(70%);
            filter: grayscale(70%);
    cursor: pointer;
}
.card__clz:hover {
    -webkit-transform: scale(1.5);
    transform: scale(1.1);
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
}
.home-page-card-title__clz {
    letter-spacing: 0px;
    color: #f2efef;
    margin-top: 0px;
    margin-left: 0px;
    text-transform: uppercase;
    font-family: "Bebas Neue";
    font-style: normal;
    font-display: swap;
    margin-bottom: 0px;
    font-size: 45px;
    line-height: 45px;
}
.home-page-card-description__clz {
    letter-spacing: 1px;
    color: #f2efef;
    opacity: 0.7;
    text-transform: uppercase;
    font-size: 17px;
    font-family: "Bebas Neue";
    font-size: 32px;
    line-height: 30px;
    padding-top: 5px;
}
.title__clz {
    text-align: center;
    padding-top: 22px;
    padding-right: 27%;
    padding-left: 28%;
    padding-bottom: 40px;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 0%;
        padding-left: 0%;
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 6%;
        padding-left: 4%;
        padding-bottom: 40px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    .title__clz {
        text-align: center;
        padding-top: 22px;
        padding-right: 4%;
        padding-left: 4%;
        padding-bottom: 40px;
    }
}
.etk-title__clz {
    text-align: center;
    padding-top: 22px;
    font-size: 55px;
    color: #2f2b2b;
    font-weight: bold;
    font-family: "Bebas Neue";
}
.opacity-title__clz {
    opacity: 0.7;
    font-style: normal;
    font-display: swap;
    font-weight: bold;
    font-size: 55px;
    font-family: "Bebas Neue";
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

#nprogress .bar,
#nprogress .spinner {
    z-index: 999999 !important;
}
.password-toggle__2 {
    position: absolute;
    padding-top: 18px !important;
    right: 26px;
    opacity: 0.6;
}
.password-toggle__1 {
    position: absolute;
    right: 26px;
    opacity: 0.6;
}
.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}

@media (max-width: 576px) {
    .login-logo__clz {
        margin-left: 23vw;
        margin-top: 2vh;
        width: 35vh;
    }
    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }

    .etk-logo__clz {
        top: 5%;
        position: absolute;
        -webkit-transform: scale(0.8);
                transform: scale(0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
                clip-path: polygon(0 0, 100% 0%, 100% 33%, 0 40%);
        width: 100%;
        height: 100vh;
    }
    .video {
        width: auto !important;
        height: 100vh !important;
    }
    .login-card {
        top: 60% !important;
        left: 50% !important;
    }
}

@media (min-width: 576px) and (max-width: 768px) {
    .login-logo__clz {
        margin-left: 28vw;
        margin-top: 2vh;
        margin-bottom: 4vh;
        width: 35vh;
    }
    .slogan {
        margin-left: 30vw;
        width: 35vh;
    }
    .etk-logo__clz {
        width: 80%;
        top: 2%;
        left: 10%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
                clip-path: polygon(0 0, 100% 0%, 100% 36%, 0 45%);
        width: 100%;
        height: 100vh;
    }
    .video {
        width: auto !important;
        height: 100vh !important;
    }
    .login-card {
        top: 55% !important;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .login-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }
    .video {
        width: auto !important;
        height: 100% !important;
    }
    .login-card {
        top: 60% !important;
        left: 55% !important;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .login-logo__clz {
        margin-left: 3vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 9vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        width: 40%;
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 50%;
    }
    .video {
        width: auto;
        height: 100vh;
    }
    .login-card {
        top: 55% !important;
        left: 55% !important;
    }
}

@media (min-width: 1200px) {
    .login-logo__clz {
        margin-left: 4vw;
        margin-top: 6vh;
        margin-bottom: 18vh;
        width: 40vh;
    }
    .slogan {
        margin-left: 10vw;
        width: 40vh;
        margin-top: 3vh;
    }
    .etk-logo__clz {
        top: 5%;
        left: 4%;
        position: absolute;
        -webkit-transform: translate(0%, 15%);
                transform: translate(0%, 15%);
        width: 30%;
    }
    .white-section {
        -webkit-clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
                clip-path: polygon(0 0, 100% 0%, 51% 100%, 0% 100%);
        width: 40vw;
    }
    .video {
        width: 120%;
        height: auto;
    }

    .login-card {
        left: 60% !important;
    }
}

.login-btn__clz {
    box-shadow: 0px 15px 20px 7px #bfbdbd !important;
}

.login-btn__clz:hover {
    background-color: white !important;
    color: black !important;
}

.login-card {
    font-family: "Poppins";
    left: 60% !important;
    top: 50% !important;
    width: 30% !important;
    height: 47% !important;
}

.login-card h3 {
    font-size: 35px;
}

.login-card .form-group label {
    font-size: 20px;
}

.password-toggle__clz {
    position: absolute;
    padding-top: 42px;
    right: 35px;
    opacity: 0.6;
}

.form-control__clz.is-invalid {
    background-image: none !important;
}

.new-password-toggle__clz {
    position: absolute;
    padding-top: 8px;
    right: 26px;
    opacity: 0.6;
}

.confirmed-password-toggle__clz {
    position: absolute;
    padding-top: 23px;
    right: 26px;
    opacity: 0.6;
}
.submit-button__clz {
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
    margin-left: 31%;
}

